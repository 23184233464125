import React from 'react';
import {SkeletonBlock} from 'skeleton-elements/react';
import classNames from './style.module.css';

type Props = {
  style?: React.CSSProperties;
  imageStyle?: React.CSSProperties;
  onClick?: (e?: React.MouseEvent<HTMLElement>) => void;
  onLoad?: () => void;
  height?: string | number;
  width?: string | number;
  fillColor?: string;
  className?: string;
  backgroundSize?: string;
  image?: string;
  backgroundColor?: string;
  imageClassName?: string;
  loader?: boolean
  children?: React.ReactChildren;
};

type State = {
  loaded: boolean;
};

export default class Image extends React.Component<Props, State> {
  static defaultProps = {
    style: {},
    imageStyle: {},
    height: '100%',
    width: '100%',
    fillColor: '',
    onLoad: () => {},
    onClick: () => {},
    className: '',
    backgroundSize: 'cover',
    image: '',
    backgroundColor: 'transparent',
    imageClassName: '',
    children: [],
    loader: true
  };

  state = {
    loaded: false,
  };

  onLoad = () => {
    this.setState({loaded: true}, () => {
      if (this.props.onLoad) this.props.onLoad();
    });
  };

  onClick = (e: React.MouseEvent<HTMLElement>) => {
    if (this.props.onClick) this.props.onClick(e);
  };

  render() {
    const {loaded} = this.state;
    const {
      className,
      imageClassName,
      width,
      height,
      image,
      backgroundSize,
      style,
      imageStyle,
      backgroundColor,
      loader,
      ...props
    } = this.props;
    return (
      <div
        {...props}
        onClick={this.onClick}
        className={className}
        style={{
          position: 'relative',
          width,
          height,
          ...style,
          overflow: 'hidden',
        }}
      >
        {!loaded && (
          <div style={{backgroundColor}} className={classNames.placeholder}>
            {loader && (
              <SkeletonBlock
                //@ts-ignore
                width={width}
                //@ts-ignore
                height={height}
                effect="blink"
                style={{position: 'relative', ...imageStyle}}
              />
            )}
          </div>
        )}
        {image && (
          <React.Fragment>
            <div
              className={`${classNames.image} ${
                !loaded && image ? classNames.loading : ''
              } ${imageClassName}`}
              style={{
                backgroundSize,
                backgroundImage: `url(${image})`,
                backgroundColor,
                ...imageStyle,
              }}
            >
              {this.props.children}
            </div>
            <img
              alt=""
              src={image}
              onLoad={this.onLoad}
              style={{display: 'none'}}
            />
          </React.Fragment>
        )}
      </div>
    );
  }
}
