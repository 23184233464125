// App.jsx
import React, {useEffect, useState} from 'react';
import {inject, observer} from 'mobx-react';
import {
  Page,
  NavRight,
  Navbar,
  Link,
  Toolbar,
  Tabs,
  Tab,
  Searchbar,
  List,
  ListItem,
  Toggle,
  BlockTitle,
  f7,
  ListInput,
  Block,
  Button,
  Preloader,
} from 'framework7-react';
import {store} from '../stores';
import {LoadScreen} from '../helpers/f7';
import {authEmployee, getPoints, getProfile} from '../apollo';
import {setApolloToken} from '../apollo/client';

export const LoadingPage = observer(() => {
  const onInit = () => {
    if (localStorage.selectPoint) {
      store.selectPoint = JSON.parse(localStorage.selectPoint);
    }

    getPoints().then((e: any) => {
      console.log('e.data.points', e.data.points);
      store.points = e.data.points;
    });

    getProfile()
      .then((e: any) => {
        store.profile = e.data.profile;
        LoadScreen('/calc', {clearPreviousHistory: true});
      })
      .catch(() => {
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      });
  };

  useEffect(() => {
    if (localStorage.token) {
      setApolloToken(localStorage.token);
      onInit();
    } else {
      LoadScreen('/login', {clearPreviousHistory: true});
    }
  }, []);

  return (
    <Page noNavbar>
      <div
        className="display-flex align-items-center justify-content-center flex-direction-column"
        style={{height: '100%', width: '100%'}}
      >
        <img
          width="100"
          style={{marginBottom: 30}}
          src={require('../assets/images/icon.png')}
        />
        <Preloader />
      </div>
    </Page>
  );
});
