// App.jsx
import React, {useEffect, useRef, useState} from 'react';
import {inject, observer} from 'mobx-react';
import {Link, Toolbar, Views, View} from 'framework7-react';

export const MainPage = observer(() => {
  return (
    <React.Fragment>
      <Toolbar labels tabbar position={'bottom'} className={'main_toolbar'}>
        <Link tabLinkActive tabLink={`#main-tab-0`}>
          <i className="f7-icons">barcode_viewfinder</i>
          <span className="tabbar-label">Покупка</span>
        </Link>
        <Link tabLink={`#main-tab-1`}>
          <i className="f7-icons">money_dollar_circle</i>
          <span className="tabbar-label">История</span>
        </Link>
        <Link tabLink={`#main-tab-2`}>
          <i className="f7-icons">person_alt_circle_fill</i>
          <span className="tabbar-label">Профиль</span>
        </Link>
      </Toolbar>
      <Views tabs>
        <View
          tabActive
          id="main-tab-0"
          tab
          name="order"
          url="/order"
          stackPages
        />
        <View
          id="main-tab-1"
          tab
          name="transactions"
          url="/transactions"
          stackPages
        />
        <View id="main-tab-2" tab name="profile" url="/profile" stackPages />
      </Views>
    </React.Fragment>
  );
});
