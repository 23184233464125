import {
  Page,
  NavRight,
  Navbar,
  Link,
  Toolbar,
  Tabs,
  Tab,
  Searchbar,
  List,
  ListItem,
  Toggle,
  BlockTitle,
  f7,
  ListInput,
  Block,
  Button,
  Popup,
  Segmented,
  ListItemRow,
  AccordionContent,
} from 'framework7-react';
import {observer} from 'mobx-react';
import React, {useEffect, useRef, useState} from 'react';
import {getClient} from '../../apollo';
import ClientPopup from '../../components/ClientPopup';
import {store} from '../../stores';
import {ClientType, PointType} from '../../types/entity';

type Props = {};

const ProfileTab = observer((props: Props) => {
  const onLogOut = () => {
    window.location.reload();
    localStorage.clear();
  };
  return (
    <Page>
      <Navbar title={'Профиль'}></Navbar>
      <List>
        <ListItem header="Ваше ФИО" title={store.profile.name}></ListItem>
        <ListItem
          header="Рейтинг"
          title={store.profile.raiting || 5}
        ></ListItem>
        <ListItem
          header="Средний чек"
          title={store.profile.averageCheck || 0}
        ></ListItem>
        <ListItem
          header="Кол-во чеков"
          title={store.profile.countChecks || 0}
        ></ListItem>
      </List>

      <Block>
        <Button onClick={onLogOut} fill large>
          Выход
        </Button>
      </Block>
    </Page>
  );
});

export default ProfileTab;
