import React, {useEffect, useRef} from 'react';
import {inject, observer} from 'mobx-react';
import {Capacitor} from '@capacitor/core';
import {Network} from '@capacitor/network';
import {App as F7App, View, f7, f7ready} from 'framework7-react';
import {LoadingPage, LoginPage, MainPage} from './pages';
import {f7Helpers} from './helpers';
import {ErrorBoundary} from './components';
import './assets/styles/app.scss';
import OrderTab from './pages/Tabs/OrderTab';
import 'framework7-icons';
import TransactionsTab from './pages/Tabs/TransactionsTab';
import ProfileTab from './pages/Tabs/ProfileTab';
import {CalcPage} from './pages/CalcPage';

declare global {
  interface Window {
    inDev: () => void;
  }
}

const f7params = {
  name: 'LC Cashier',
  id: 'ru.apptor.app',
  dialog: {
    buttonOk: 'Oк',
    buttonCancel: 'Отмена',
  },
  navbar: {
    //mdCenterTitle: true//центрирование тайтла в навбаре на андроид
  },
  // specify routes for app
  routes: [
    {
      path: '/calc',
      component: CalcPage,
    },
    {
      path: '/order',
      component: OrderTab,
    },
    {
      path: '/transactions',
      component: TransactionsTab,
    },
    {
      path: '/profile',
      component: ProfileTab,
    },
    {
      path: '/',
      component: LoadingPage,
    },
    {
      path: '/login',
      component: LoginPage,
    },
    {
      path: '/main',
      component: MainPage,
    },
  ],
};
export const App = () => {
  let toastOffline: any = useRef(null);

  useEffect(() => {
    networkConnectionHandler();

    window.inDev = () => f7Helpers.toast('Функция в разработке');

    //блур с активного элемента при переходе между экранами
    f7.views.main.router.on(
      'routeChange',
      (newRoute, previousRoute, router) => {
        //@ts-ignore
        window.document.activeElement.blur();
      }
    );

    if (Capacitor.isNativePlatform()) {
      window.screen.orientation.lock('portrait');
      f7Helpers.handleBackButton();
      f7Helpers.handleKeyboard();
      f7Helpers.addHapticListeners();
    }
  }, []);

  const networkConnectionHandler = async () => {
    toastOffline.current = f7.toast.create({
      horizontalPosition: 'center',
      text: 'Проблемы с интернет соединением...',
    });

    if (Capacitor.isNativePlatform()) {
      const networkStatus = await Network.getStatus();
      changeShowToastOffline(networkStatus.connected);
      Network.addListener('networkStatusChange', (status) => {
        changeShowToastOffline(status.connected);
      });
    } else {
      changeShowToastOffline(window.navigator.onLine);
      window.addEventListener('online', () => {
        changeShowToastOffline(true);
      });
      window.addEventListener('offline', () => {
        changeShowToastOffline(false);
      });
    }
  };

  const changeShowToastOffline = (isOnline: boolean) => {
    if (isOnline) {
      toastOffline.current.close();
    } else {
      toastOffline.current.open();
    }
  };
  return (
    <ErrorBoundary>
      <F7App {...f7params}>
        <View main stackPages url="/" />
      </F7App>
    </ErrorBoundary>
  );
};
