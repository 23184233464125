// App.jsx
import React, {useEffect, useState} from 'react';
import {inject, observer} from 'mobx-react';
import {
  Page,
  NavRight,
  Navbar,
  Link,
  Toolbar,
  Tabs,
  Tab,
  Searchbar,
  List,
  ListItem,
  Toggle,
  BlockTitle,
  f7,
  ListInput,
  Block,
  Button,
  BlockFooter,
} from 'framework7-react';
import {store} from '../stores';
import {LoadScreen} from '../helpers/f7';
import {authEmployee} from '../apollo';

export const LoginPage = observer(() => {
  const [login, setLogin] = useState<string>();
  const [password, setPassword] = useState<string>();

  const onLogin = () => {
    if (login && password) {
      authEmployee({
        login: login,
        password: password,
      });
    }
  };

  return (
    <Page>
      <Navbar title="Авторизация"></Navbar>
      <Block className="display-flex align-items-center justify-content-center">
        <img width="100" src={require('../assets/images/icon.png')} />
      </Block>
      <List noHairlinesMd>
        <ListInput
          label="Логин"
          type="text"
          onChange={(e) => setLogin(e.target.value)}
          placeholder="Ваш логин"
        ></ListInput>

        <ListInput
          label="Пароль"
          type="password"
          onChange={(e) => setPassword(e.target.value)}
          placeholder="Ваш пароль"
        ></ListInput>
      </List>

      <Block>
        <Button disabled={!login || !password} onClick={onLogin} large fill>
          Вход
        </Button>
      </Block>
      <BlockFooter>
        Войдите под своим логином и паролем сотрудника, чтобы начать работу с
        оплатами.
      </BlockFooter>
    </Page>
  );
});
