import React, {
  FC,
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react';
import {
  Page,
  NavRight,
  Navbar,
  Link,
  Toolbar,
  Tabs,
  Tab,
  Searchbar,
  List,
  ListItem,
  Toggle,
  BlockTitle,
  f7,
  ListInput,
  Block,
  Button,
  Popup,
  Segmented,
  ListItemRow,
  AccordionContent,
} from 'framework7-react';
import {Loader} from '.';
import {ClientType} from '../types/entity';
import {toast} from '../helpers/f7';
import {createTransaction} from '../apollo';
import {store} from '../stores';

const ClientPopup = forwardRef(({}, ref) => {
  const [sum, setSum] = useState<number>(0);
  const [totalSum, setTotal] = useState<number>(0);
  const [type, setType] = useState<'plus' | 'minus'>('plus');
  const [client, setClient] = useState<ClientType>();
  const [opened, setOpened] = useState<boolean>(false);

  const [spend, setSpend] = useState<number>(0);

  useImperativeHandle(ref, () => ({
    open: (clientInput: ClientType) => open(clientInput),
  }));

  const open = (clientInput: ClientType) => {
    if (clientInput?._id) {
      setClient(clientInput);
      setType('plus');
      setSum(0);
      setSpend(0);
      setOpened(true);
    }
  };

  const close = () => {
    setClient(undefined);
    setType('plus');
    setSum(0);
    setSpend(0);
    setOpened(false);
  };

  const onTransaction = () => {
    if (store.selectPoint._id && client?._id) {
      f7.preloader.show();
      createTransaction({
        client: client._id,
        point: store.selectPoint._id,
        amount: +sum,
        spend: +spend,
      })
        .then(() => {
          close();
        })
        .finally(() => {
          f7.preloader.hide();
        });
    }
  };

  useEffect(() => {
    if (client?._id) {
      let total = 0;
      if (client?.level.type == 'cashback') {
        if (type == 'minus') total = sum - spend;
        else total = sum;
      } else {
        total = Math.floor(sum * (client.level.percent / 100));
      }
      setTotal(total);
    }
  }, [sum, spend]);

  const onSpend = (val: number) => {
    if (client?._id) {
      let max_write_off =
        client.level.max_write_off > 0 ? client.level.max_write_off : 100;
      //Проверка на максимальный процент списания
      let maxSpend = Math.floor(sum * (max_write_off / 100));

      if (val > client.balance) {
        toast('Нельзя списать баллов больше, чем на балансе у клиента');
        setSpend(client.balance);
        return;
      }

      if (val > maxSpend) {
        toast('Доступно максимальное списание от чека ' + max_write_off + '%');
        setSpend(maxSpend);
        return;
      }

      setSpend(val);
    }
  };

  return (
    <Popup opened={opened}>
      <Page>
        <Navbar title="Операция">
          <NavRight>
            <Link onClick={close}>Отмена</Link>
          </NavRight>
        </Navbar>
        <Toolbar position="bottom" inner={false}>
          <Button onClick={onTransaction} fill large>
            Провести операцию
          </Button>
        </Toolbar>
        {client?._id && (
          <>
            <List accordionList>
              <ListItem
                accordionItem
                title={
                  'Клиент ' +
                  client?.phone +
                  ' ' +
                  (client?.name || '') +
                  ' ' +
                  (client?.surname || '')
                }
              >
                <AccordionContent>
                  <List>
                    {client?.name && (
                      <ListItem
                        header="Имя"
                        title={client?.name || 'Не указанно'}
                      ></ListItem>
                    )}
                    {client?.surname && (
                      <ListItem
                        header="Фамилия"
                        title={client?.surname || 'Не указанно'}
                      ></ListItem>
                    )}
                    {client?.phone && (
                      <ListItem
                        header="Номер телефона"
                        title={client?.phone}
                      ></ListItem>
                    )}

                    <ListItem
                      header="Уровень"
                      title={
                        (client?.level.type == 'cashback'
                          ? 'Бонусы'
                          : 'Скидка') +
                        ' ' +
                        client?.level.percent +
                        '%'
                      }
                    ></ListItem>

                    {client?.level.type == 'cashback' && (
                      <ListItem
                        header="Макс. процент списания"
                        title={client?.level.max_write_off + '%'}
                      ></ListItem>
                    )}

                    {client?.level.type == 'cashback' && (
                      <ListItem
                        header="Баланс"
                        title={client?.balance}
                      ></ListItem>
                    )}
                  </List>
                </AccordionContent>
              </ListItem>
            </List>

            <BlockTitle>Сумма покупки</BlockTitle>
            <List inlineLabels noHairlinesMd>
              <ListInput
                value={sum}
                onChange={(e) => setSum(e.target.value)}
                type="tel"
              ></ListInput>
            </List>
            {client.level.type == 'cashback' && (
              <>
                <BlockTitle>Тип операции</BlockTitle>
                <Block strong>
                  <Segmented raised tag="p">
                    <Button
                      active={type == 'plus'}
                      onClick={() => setType('plus')}
                    >
                      Накопить
                    </Button>
                    <Button
                      disabled={!client.balance}
                      active={type == 'minus'}
                      onClick={() => setType('minus')}
                    >
                      Списать ({client.balance})
                    </Button>
                  </Segmented>
                </Block>
                {type == 'minus' && (
                  <>
                    <BlockTitle>Бонусов к списанию</BlockTitle>
                    <List inlineLabels noHairlinesMd>
                      <ListInput
                        value={spend}
                        onChange={(e) => onSpend(e.target.value)}
                        type="tel"
                      ></ListInput>
                    </List>
                  </>
                )}
              </>
            )}
            <BlockTitle medium>
              Сумма чека: {sum}
              <br />
              {client.level.type == 'cashback' && (
                <>
                  {type == 'minus' && 'Списание баллов: ' + spend}
                  {type == 'plus' &&
                    'Начисление баллов: ' +
                      Math.floor(sum * (client.level.percent / 100))}
                  <br />
                </>
              )}
              К оплате: {totalSum}
            </BlockTitle>
          </>
        )}
      </Page>
    </Popup>
  );
});

export default ClientPopup;
