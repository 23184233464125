import {ApolloClient, InMemoryCache, HttpLink, from} from '@apollo/client';
import {onError} from '@apollo/client/link/error';
import {ServerName} from '../config';
import {toast} from '../helpers/f7';

const errorLink = onError(
  ({graphQLErrors, networkError, response, operation, forward}) => {
    console.log({graphQLErrors, networkError, response, operation, forward});
    if (graphQLErrors && graphQLErrors.length > 0) {
      if (
        graphQLErrors[0]?.extensions?.type === 'UNAUTHORIZED' ||
        graphQLErrors[0]?.extensions?.type === 'UNAUTHENTICATED'
      ) {
        localStorage.clear();
        window.location.reload();
      }
    }
    /* else if (networkError && networkError?.statusCode === 500) {
      } */

    if (graphQLErrors && graphQLErrors.length > 0) {
      // if (graphQLErrors[0].message) {
      //   message.error(graphQLErrors[0].message);
      // toast(graphQLErrors[0].message);
      if (graphQLErrors[0]?.extensions?.message) {
        toast(graphQLErrors[0]?.extensions?.message as string);
      }
    }
  }
);

// Данная функция нужна что бы обновить token в аполоклиенте после авторизации
export const setApolloToken = (token: string) => {
  const httpLink = new HttpLink({
    uri: `${ServerName}`,
    headers: {
      token: token,
      'Content-Type': 'application/json',
    },
  });

  apolloClient.setLink(from([errorLink, httpLink]));
};

// Линк для аполло
const httpLink = new HttpLink({
  uri: `${ServerName}`,
  headers: {
    // token: '',
  },
});

const link = from([errorLink, httpLink]);

//Клиент аполло
const apolloClient = new ApolloClient({
  link,
  cache: new InMemoryCache(),
  defaultOptions: {
    watchQuery: {
      fetchPolicy: 'no-cache',
    },
    query: {
      fetchPolicy: 'no-cache',
    },
    mutate: {
      fetchPolicy: 'no-cache',
    },
  },
});

export {apolloClient};
