import React, {
  FC,
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react';
import {
  Page,
  NavRight,
  Navbar,
  Link,
  Toolbar,
  Tabs,
  Tab,
  Searchbar,
  List,
  ListItem,
  Toggle,
  BlockTitle,
  f7,
  ListInput,
  Block,
  Button,
  Popup,
  Segmented,
  ListItemRow,
  AccordionContent,
  Card,
} from 'framework7-react';
import {Loader} from '.';
import {ClientType} from '../types/entity';
import {toast} from '../helpers/f7';
import {createTransaction} from '../apollo';
import {store} from '../stores';

const ClientCalcPopup = forwardRef(({}, ref) => {
  const [sum, setSum] = useState<number>(0);
  const [totalSum, setTotal] = useState<number>(0);
  const [type, setType] = useState<'plus' | 'minus'>('minus');
  const [client, setClient] = useState<ClientType>();
  const [opened, setOpened] = useState<boolean>(false);
  const [percent, setPercent] = useState<number>(0);
  const [spend, setSpend] = useState<number>(0);

  useImperativeHandle(ref, () => ({
    open: (clientInput: ClientType) => open(clientInput),
  }));

  const open = (clientInput: ClientType) => {
    if (clientInput?._id) {
      setClient(clientInput);
      setType('minus');
      setSum(0);
      setSpend(0);
      setOpened(true);
    }
  };

  const close = () => {
    setClient(undefined);
    setType('minus');
    setSum(0);
    setSpend(0);
    setOpened(false);
  };

  useEffect(() => {
    if (client?._id) {
      if (client?.level.type == 'cashback') {
        let max_write_off =
          client.level.max_write_off > 0 ? client.level.max_write_off : 100;
        //Максимальный процент списания
        let maxSpend = Math.floor(sum * (max_write_off / 100));

        //Можем списать
        let spendVal = maxSpend > client.balance ? client.balance : maxSpend;

        //Округляем до целого процента
        let calPercent = Math.floor((spendVal / sum) * 100);

        //Вычисляем новое значение с учетом круглой скидки
        spendVal = Math.floor(sum * (calPercent / 100) * 100) / 100;
        setPercent(calPercent);
        setSpend(spendVal);
      }

      /* let total = 0;
      if (client?.level.type == 'cashback') {
        let max_write_off =
          client.level.max_write_off > 0 ? client.level.max_write_off : 100;
        //Проверка на максимальный процент списания
        let maxSpend = Math.floor(sum * (max_write_off / 100));

        let spendVal = maxSpend > client.balance ? client.balance : maxSpend;

        let calPercent = Math.floor((spendVal / sum) * 100);
        if (calPercent > 1) {
          setPercent(calPercent);
          console.log(percent);
          spendVal = (sum * percent) / 100;
        } else {
          spendVal = 0;
          setPercent(0);
        }
        setSpend(spendVal);

        if (type == 'minus') total = sum - spendVal;
        else total = sum;
      } else {
        total = Math.floor(sum * (client.level.percent / 100));
      }
      setTotal(total);*/
    }
  }, [sum]);

  return (
    <Popup opened={opened}>
      <Page>
        <Navbar title="Оплата">
          <NavRight>
            <Link onClick={close}>Закрыть</Link>
          </NavRight>
        </Navbar>

        {client?._id && (
          <>
            <List accordionList>
              <ListItem
                accordionItem
                title={
                  'Клиент ' +
                  client?.phone +
                  ' ' +
                  (client?.name || '') +
                  ' ' +
                  (client?.surname || '')
                }
              >
                <AccordionContent>
                  <List>
                    {client?.name && (
                      <ListItem
                        header="Имя"
                        title={client?.name || 'Не указанно'}
                      ></ListItem>
                    )}
                    {client?.surname && (
                      <ListItem
                        header="Фамилия"
                        title={client?.surname || 'Не указанно'}
                      ></ListItem>
                    )}
                    {client?.phone && (
                      <ListItem
                        header="Номер телефона"
                        title={client?.phone}
                      ></ListItem>
                    )}

                    <ListItem
                      header="Уровень"
                      title={
                        (client?.level.type == 'cashback'
                          ? 'Бонусы'
                          : 'Скидка') +
                        ' ' +
                        client?.level.percent +
                        '%'
                      }
                    ></ListItem>

                    {client?.level.type == 'cashback' && (
                      <ListItem
                        header="Макс. процент списания"
                        title={client?.level.max_write_off + '%'}
                      ></ListItem>
                    )}

                    {client?.level.type == 'cashback' && (
                      <ListItem
                        header="Баланс"
                        title={client?.balance}
                      ></ListItem>
                    )}
                  </List>
                </AccordionContent>
              </ListItem>
            </List>

            <BlockTitle>Сумма покупки</BlockTitle>
            <List inlineLabels noHairlinesMd>
              <ListInput
                value={sum}
                onChange={(e) => setSum(+e.target.value)}
                type="tel"
              ></ListInput>
            </List>

            {sum > 0 && (
              <Card>
                <List className="itog">
                  {client.level.type == 'cashback' && (
                    <ListItem
                      title={
                        type == 'minus'
                          ? 'Списание баллов'
                          : 'Начисление баллов'
                      }
                      after={spend + ' (' + percent + '%)'}
                    ></ListItem>
                  )}

                  <ListItem title={'Скидка'} after={percent + '%'}></ListItem>
                  <ListItem title="К оплате" after={sum - spend}></ListItem>
                </List>
              </Card>
            )}
          </>
        )}
      </Page>
    </Popup>
  );
});

export default ClientCalcPopup;
