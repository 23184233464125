import {FetchResult, gql} from '@apollo/client';
import {rejects} from 'assert';
import exp from 'constants';
import {resolve} from 'dns';
import {toast} from '../helpers/f7';
import {ClientType} from '../types/entity';
import {apolloClient} from './client';

export const getProfile = async () => {
  return await apolloClient.query({
    query: gql`
      query getProfile {
        profile {
          _id
          name
          raiting
          averageCheck
          countChecks
        }
      }
    `,
  });
};

export const createTransaction = (params: {
  client: string;
  point: string;
  amount: number;
  spend: number;
}) => {
  return new Promise<Boolean>((resolve, reject) => {
    apolloClient
      .mutate<{
        token: string;
      }>({
        mutation: gql`
          mutation createTransaction(
            $client: String!
            $point: String!
            $amount: Float!
            $spend: Float!
          ) {
            transaction(
              client: $client
              point: $point
              amount: $amount
              spend: $spend
            )
          }
        `,
        variables: params,
      })
      .then((json: any) => {
        console.log(json);

        resolve(true);
      })
      .catch((err) => {
        reject();
      });
  });
};

export const getTransactions = async () => {
  return await apolloClient.query({
    query: gql`
      query getTransactions {
        transactions {
          _id
          date
          discount
          amount
          scores
          point {
            _id
            title
          }
          feedback {
            msg
            rating
          }
        }
      }
    `,
  });
};

export const authEmployee = (params: {login: string; password: string}) => {
  return new Promise<{
    token: string;
  }>((resolve, reject) => {
    apolloClient
      .mutate<{
        token: string;
      }>({
        mutation: gql`
          mutation authEmployee($login: String!, $password: String!) {
            auth(login: $login, password: $password) {
              token
            }
          }
        `,
        variables: params,
      })
      .then((json: any) => {
        if (json.data.auth.token) {
          localStorage.setItem('token', json.data.auth.token);
          window.location.reload();
        }

        resolve({
          token: json.data.auth.token,
        });
      })
      .catch((err) => {
        reject();
      });
  });
};

export const getPoints = async () => {
  return await apolloClient.query({
    query: gql`
      query getPoints {
        points {
          _id
          title
          city {
            title
          }
          address {
            address
          }
        }
      }
    `,
  });
};

export const getClient = (params: {search: string; point: string}) => {
  return new Promise<{
    token: string;
  }>((resolve, reject) => {
    apolloClient
      .query<ClientType>({
        query: gql`
          query getClient($search: String!, $point: String!) {
            client(search: $search, point: $point) {
              _id
              phone
              birthday
              name
              surname
              level {
                title
                type
                percent
                max_write_off
              }
              email
              balance
              photo
              gender
            }
          }
        `,
        variables: params,
      })
      .then((json: any) => {
        resolve(json.data.client);
      })
      .catch((err) => {
        reject();
      });
  });
};
