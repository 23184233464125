// App.jsx
import React, {useEffect, useRef, useState} from 'react';
import {inject, observer} from 'mobx-react';
import {
  Page,
  NavRight,
  Navbar,
  Link,
  Toolbar,
  Tabs,
  Tab,
  Searchbar,
  List,
  ListItem,
  Toggle,
  BlockTitle,
  f7,
  ListInput,
  Block,
  Button,
  BlockFooter,
} from 'framework7-react';
import {store} from '../stores';
import {LoadScreen} from '../helpers/f7';
import {authEmployee, getClient} from '../apollo';
import {ClientType} from '../types/entity';
import ClientPopup from '../components/ClientPopup';
import ClientCalcPopup from '../components/ClientCalcPopup';

export const CalcPage = observer(() => {
  const [search, setSearch] = useState<string>();
  const popupClient = useRef<any>();
  const [client, setClient] = useState<ClientType | null>(null);

  const onSearch = () => {
    if (search && store.points[0]._id) {
      f7.preloader.show();
      getClient({
        search: search,
        point: store.points[0]._id,
      })
        .then((e: any) => {
          setClient(e);
          setSearch('');
          popupClient.current.open(e);
        })
        .finally(() => {
          f7.preloader.hide();
        });
    }
  };

  return (
    <Page>
      <Navbar title="Расчет скидки" subtitle="Модуль для AWC"></Navbar>
      <Block style={{textAlign: 'center'}}>
        Введите код клиента, номер Wallet карты
        <br />
        или номер телефона.
      </Block>
      <List inlineLabels noHairlinesMd>
        <ListInput
          placeholder="Номер или телефон"
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          type="tel"
        ></ListInput>
      </List>
      <Block style={{textAlign: 'center'}}>
        Используйте межународный формат телефонных номеров, например,
        79201234567
      </Block>
      <Block>
        <Button onClick={onSearch} disabled={!search} large fill>
          Продолжить
        </Button>
      </Block>

      <ClientCalcPopup ref={(ref) => (popupClient.current = ref)} />
    </Page>
  );
});
