import React from 'react';
import ReactDOM from 'react-dom';
import {Provider} from 'mobx-react';
import Framework7 from 'framework7/lite-bundle';
import 'framework7/framework7-bundle.min.css';
import Framework7React from 'framework7-react';
import {App} from './App';
import * as stores from './stores';
import reportWebVitals from './reportWebVitals';

Framework7.use(Framework7React);

const startApp = () => {
  ReactDOM.render(
    <React.StrictMode>
      <Provider {...stores}>
        <App />
      </Provider>
    </React.StrictMode>,
    document.getElementById('root')
  );
};

startApp();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
