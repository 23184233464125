import {makeAutoObservable} from 'mobx';
import {PointType, ProfileType} from '../types/entity';

class Store {
  constructor() {
    makeAutoObservable(this);
  }

  profile: ProfileType = {};
  points: Array<PointType> = [];
  selectPoint: PointType = {};
  likesCount: number = 1;

  get getLikesCount() {
    return this.likesCount;
  }

  set setLikesCount(value: number) {
    this.likesCount = value;
  }
}

export default new Store();
