import {
  Page,
  NavRight,
  Navbar,
  Link,
  Toolbar,
  Tabs,
  Tab,
  Searchbar,
  List,
  ListItem,
  Toggle,
  BlockTitle,
  f7,
  ListInput,
  Block,
  Button,
  Popup,
  Segmented,
  ListItemRow,
  AccordionContent,
  Chip,
} from 'framework7-react';
import {observer} from 'mobx-react';
import React, {useEffect, useRef, useState} from 'react';
import {getClient, getTransactions} from '../../apollo';
import ClientPopup from '../../components/ClientPopup';
import {store} from '../../stores';
import {ClientType, PointType, TransactionType} from '../../types/entity';
import moment from 'moment';

type Props = {};

const TransactionsTab = observer((props: Props) => {
  const [transactions, setTransactions] = useState<Array<TransactionType>>([]);
  const [transaction, setTransaction] = useState<TransactionType | null>();

  const getDate = () => {
    getTransactions().then((e: any) => {
      // console.log('e', e?.data?.transactions);
      setTransactions(e?.data?.transactions);
    });
  };

  const onOpen = (item: TransactionType) => {
    setTransaction(item);
  };

  useEffect(() => {
    getDate();
  }, []);

  return (
    <Page onPageTabShow={() => getDate()}>
      <Navbar title={'История'}></Navbar>
      <List mediaList>
        {transactions.map((item) => {
          return (
            <ListItem
              link="#"
              onClick={() => onOpen(item)}
              subtitle={'Сумма ' + item.amount}
              title={moment.unix(item.date).format('DD.MM.YYYY hh:mm')}
              text={item.point.title}
            >
              <div slot="after">
                <span color={item.scores > 0 ? 'green' : 'red'}>
                  {item.scores ? item.scores : item.discount + '%'}
                </span>
              </div>
            </ListItem>
          );
        })}
      </List>

      <Popup
        opened={transaction?._id ? true : false}
        onPopupClosed={() => setTransaction(null)}
        swipeToClose
      >
        <Page>
          <Navbar title="Оплата">
            <NavRight>
              <Link popupClose>Закрыть</Link>
            </NavRight>
          </Navbar>

          <List>
            <ListItem header="Номер" title={transaction?._id}></ListItem>
            <ListItem
              header="Заведение"
              title={transaction?.point.title || ''}
            ></ListItem>
            {transaction?.date && (
              <ListItem
                header="Дата и время"
                title={moment
                  .unix(transaction?.date)
                  .format('DD.MM.YYYY hh:mm')}
              ></ListItem>
            )}

            <ListItem header="Сумма" title={transaction?.amount}></ListItem>
            <ListItem
              header="Скидка"
              title={transaction?.discount || 0}
            ></ListItem>
            <ListItem
              header="Бонусов"
              title={transaction?.scores || 0}
            ></ListItem>
          </List>
        </Page>
      </Popup>
    </Page>
  );
});

export default TransactionsTab;
