import {
  Page,
  NavRight,
  Navbar,
  Link,
  Toolbar,
  Tabs,
  Tab,
  Searchbar,
  List,
  ListItem,
  Toggle,
  BlockTitle,
  f7,
  ListInput,
  Block,
  Button,
  Popup,
  Segmented,
  ListItemRow,
  AccordionContent,
} from 'framework7-react';
import {observer} from 'mobx-react';
import React, {useEffect, useRef, useState} from 'react';
import {getClient} from '../../apollo';
import ClientPopup from '../../components/ClientPopup';
import {store} from '../../stores';
import {ClientType, PointType} from '../../types/entity';
import {BarcodeScanner} from '@awesome-cordova-plugins/barcode-scanner';

type Props = {};

const OrderTab = observer((props: Props) => {
  const [pointsOpened, setPointsOpened] = useState<boolean>(false);

  const [search, setSearch] = useState<string>();

  const [client, setClient] = useState<ClientType | null>(null);

  const popupClient = useRef<any>();

  useEffect(() => {
    if (!store.selectPoint?._id) {
      setPointsOpened(true);
    }
  });

  const onLogOut = () => {
    localStorage.clear();
    window.location.reload();
  };

  const onSelectCity = (point: PointType) => {
    store.selectPoint = point;
    localStorage.setItem('selectPoint', JSON.stringify(point));
    setPointsOpened(false);
  };

  const onSearch = () => {
    if (search && store.selectPoint._id) {
      f7.preloader.show();
      getClient({
        search: search,
        point: store.selectPoint._id,
      })
        .then((e: any) => {
          setClient(e);
          setSearch('');
          popupClient.current.open(e);
        })
        .finally(() => {
          f7.preloader.hide();
        });
    }
  };

  const onScan = () => {
    BarcodeScanner.scan().then((value) => {
      setSearch(value.text);
      onSearch();
    });
  };

  return (
    <Page>
      <Navbar subtitle={store?.profile?.name} title={store.selectPoint.title}>
        <NavRight>
          <Link onClick={() => setPointsOpened(true)}>Заведение</Link>
        </NavRight>
      </Navbar>

      <Block style={{textAlign: 'center'}}>
        Введите или сканируйте код клиента, номер Wallet карты
        <br />
        или номер телефона.
      </Block>
      <List inlineLabels noHairlinesMd>
        <ListInput
          placeholder="Номер или телефон"
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          type="tel"
        ></ListInput>
      </List>
      <Block>
        <Button onClick={onScan} large outline>
          Сканировать
        </Button>
      </Block>
      <Block style={{textAlign: 'center'}}>
        Используйте межународный формат телефонных номеров,
        <br />
        например, 79201234567
      </Block>
      <Block>
        <Button onClick={onSearch} disabled={!search} large fill>
          Продолжить
        </Button>
      </Block>

      <Popup opened={pointsOpened} onPopupClosed={() => setPointsOpened(false)}>
        <Page>
          <Navbar title="Заведения"></Navbar>

          <List mediaList>
            {store.points.map((point: PointType) => {
              return (
                <ListItem
                  onClick={() => onSelectCity(point)}
                  link="#"
                  title={point.title}
                  subtitle={point.city?.title}
                  text={point.address?.address}
                ></ListItem>
              );
            })}
          </List>
        </Page>
      </Popup>

      <ClientPopup ref={(ref) => (popupClient.current = ref)} />
    </Page>
  );
});

export default OrderTab;
